<template>
    <v-card>
        <v-card-title
            class="indigo white--text"
        >
            <h3>Pedidos Feitos</h3>
        </v-card-title>

        <v-card-text class="pt-2" >
            <v-list-item-group>
                <v-list-item v-for="history in histories" :key="history.id" :to="{name: 'Historico_detalhe', params: {id: history.id}}" >
                    <template>
                        <v-list-item-content>
                            <v-list-item-title> {{history.entrega == 'S' ? 'Entrega - R$' : 'Retirada - R$'}} {{history.valorTotal}}</v-list-item-title>
                            <v-list-item-subtitle>{{ 'Pedido em ' + $moment(history.criadoEm).format('DD/MM/YYYY [ás] hh:mm')}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{status[history.status]}} {{ history.statusTexto !== null ? ' - ' + history.statusTexto : '' }} </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>

            <v-overlay :value="overlay">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-card-text>
    </v-card>

</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        data(){
            return {
                histories: [],
                overlay: false,

                status: {
                    "open" : "Recebido",
                    "prod" : "Em produção",
                    "deli" : "A caminho",
                    "clos" : "Concluido",
                    "dclo" : "Não entregue"
                }
            }
        },

        methods: {
            ...mapActions({
                getHistory: 'HISTORICO'
            }),

            getData: function(){
                this.overlay = true
                this.getHistory()
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if (resJSON.data) {
                            this.histories = resJSON.response
                        }else{
                            console.log('Error', '=>', resJSON.message)
                        } 
                    })
                    .catch(error => {
                        console.error('Exception', '=>', error)
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        },

        mounted(){
            this.getData()
        }
    }
</script>